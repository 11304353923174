<template>
  <div class="outer-page">
    <div class="outer-info">
      <div class="left-bar">
        <p>
          词汇类别
          <Poptip
            transfer
            @on-popper-hide="resetModel"
            v-model="popVisible.topOne"
          >
            <Icon
              style="cursor: pointer"
              size="18"
              type="md-add-circle"
              color="#06baa1"
            />
            <div style="width: 100%" slot="content">
              <p>
                <span>名称：</span>
                <span>
                  <Input
                    size="small"
                    style="width: 130px"
                    v-model="model.name"
                  ></Input>
                </span>
              </p>
              <p style="padding-top: 10px; text-align: center">
                <Button
                  style="margin-right: 10px"
                  size="small"
                  @click="() => addType(1)"
                  type="primary"
                  >确定</Button
                >
                <Button
                  size="small"
                  @click="() => (this.popVisible.topOne = false)"
                  >取消</Button
                >
              </p>
            </div>
          </Poptip>
        </p>
        <div>
          <div
            class="type-list"
            v-for="(item, index) in leftData"
            :key="index"
            :class="index == current_data.index ? 'active' : ''"
            @click="() => checkType(item, index)"
          >
            <span>{{ item.name }}</span>
            <span>
              <a
                class="iconfont icon-caozuo-bianji"
                style="font-size: 12px; margin-right: 10px"
                @click.stop="leftEdit(item)"
              ></a>
              <Icon
                type="md-trash"
                @click.stop="del(item)"
                color="red"
                size="18"
              />
              <Icon
                style="line-height: 60px"
                type="md-arrow-forward"
                size="16"
              />
            </span>
          </div>
          <div style="margin-top: 30px; text-align: right; padding: 0 15px">
            <Page
              :total="page.total"
              size="small"
              show-elevator
              :current="page.pageNo"
              :page-size="page.pageSize"
              @on-change="pageChange"
            />
          </div>
        </div>
      </div>
      <div class="right-bar">
        <div class="title">
          <span style="margin-right: 10px">{{
            current_data.typeName ? current_data.typeName : "字典名称"
          }}</span>
          <Poptip
            transfer
            @on-popper-hide="resetModel"
            v-model="popVisible.topTwo"
          >
            <Icon
              style="cursor: pointer"
              size="18"
              type="md-add-circle"
              color="#06baa1"
            />
            <div style="width: 100%" slot="content">
              <p>
                <span>名称：</span>
                <span>
                  <Input
                    size="small"
                    style="width: 130px"
                    v-model="model.name"
                  ></Input>
                </span>
              </p>
              <p style="padding-top: 10px; text-align: center">
                <Button
                  style="margin-right: 10px"
                  size="small"
                  @click="() => addType(2)"
                  type="primary"
                  >确定</Button
                >
                <Button
                  size="small"
                  @click="() => (this.popVisible.topTwo = false)"
                  >取消</Button
                >
              </p>
            </div>
          </Poptip>
        </div>
        <div class="right-bar-detail">
          <Tree
            empty-text="暂无数据"
            :data="data"
            :render="treeRender"
            style="overflow: hidden"
          ></Tree>
        </div>
      </div>
    </div>
    <Modal v-model="editShow" title="编辑类型" :width="400">
      <Form :label-width="110">
        <FormItem label="类型名称">
          <Input clearable v-model="model.name"></Input>
        </FormItem>
      </Form>
      <p slot="footer">
        <Button type="primary" @click="addType">提交</Button>
        <Button @click="() => (this.editShow = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      data: [],
      leftData: [],
      // 当前编辑数据模型
      edit_model: {
        name: "",
        id: "",
      },
      // 当前添加数据model
      model: {
        pid: "",
        name: "",
        id: "",
      },
      current_data: {
        //当前编辑数据的id
        edit_id: "",
        index: null,
        typeName: "",
        id: "",
      },
      popVisible: {
        topOne: false,
        topTwo: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      editShow: false,
    };
  },
  methods: {
    getList() {
      this.$post(this.$api.DICT.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        pid: 0,
      }).then((res) => {
        this.leftData = res.list;
        this.page.total = +res.total;
      });
    },
    checkType(item, index) {
      this.current_data.id = item.id;
      this.current_data.index = index;
      this.current_data.typeName = item.name;
      this.$post(this.$api.DICT.LIST, {
        pid: item.id,
      }).then((res) => {
        this.data = res.list;
      });
    },
    addType(type) {
      let params = this.model;
      if (type == 1) {
        params.pid = 0;
      } else {
        params.pid = this.current_data.id;
      }
      let url;
      if (this.model.id) {
        url = this.$api.DICT.UPDATE;
      } else {
        url = this.$api.DICT.ADD;
      }
      this.$post(url, params).then(() => {
        this.$Message.success(this.model.id ? "修改成功！" : "添加成功！");
        this.resetInfo();
        this.resetModel();
        this.getList();
        this.popVisible = {
          topOne: false,
          topTwo: false,
        };
        this.editShow = false;
      });
    },
    del(item) {
      this.$post(this.$api.DICT.DELETE, {
        id: item.id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    leftEdit(item) {
      for (let key in this.model) {
        this.model[key] = item[key];
      }
      this.editShow = true;
    },
    resetModel() {
      this.model = {
        pid: "",
        name: "",
        id: "",
      };
    },
    resetInfo() {
      this.edit_model.name = "";
      this.edit_model.id = "";
      this.current_data.edit_id = "";
      this.current_data.id = "";
      this.current_data.index = null;
      this.current_data.typeName = "";
      this.data = [];
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    edit() {
      if (this.edit_model.id && this.edit_model.name) {
        this.$post(this.$api.DICT.UPDATE, {
          ...this.edit_model,
        }).then(() => {
          this.$Message.success("编辑成功");
          this.resetInfo();
          this.getList();
        });
      } else {
        this.$Message.warning("请输入名称");
      }
    },
    delete(data) {
      this.$post(this.$api.DICT.DELETE, {
        id: data.id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.resetInfo();
        this.getList();
      });
    },
    //编辑某行
    editRow(data) {
      this.edit_model.id = data.id;
      this.edit_model.name = data.name;
      this.current_data.edit_id = data.id;
    },
    treeRender(h, { root, node, data }) {
      //判断是否为编辑
      if (this.current_data.edit_id === data.id) {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              borderBottom: "1px solid #eaeaea",
              padding: "4px 16px 4px 0 ",
            }}
          >
            <span>
              <Input
                size="small"
                value={this.edit_model.name}
                on-input={(val) => {
                  this.edit_model.name = val;
                }}
              ></Input>
            </span>
            <span>
              <Icon
                // 编辑的确定选项
                style={{ cursor: "pointer" }}
                on-click={() => this.edit()}
                type="md-checkmark"
                size="20"
                color="#00b477"
              />
              <Icon
                style={{ cursor: "pointer" }}
                on-click={() => {
                  this.edit_model.id = "";
                  this.edit_model.name = "";
                  this.current_data.edit_id = "";
                }}
                type="md-close"
                size="20"
                color="red"
              />
            </span>
          </span>
        );
      }
      return (
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            borderBottom: "1px solid #eaeaea",
            padding: "4px 16px 4px 0 ",
          }}
        >
          <span>{data.name}</span>
          <span>
            <a
              on-click={() => this.editRow(data)}
              style={{ marginRight: "8px", color: "#00D494" }}
            >
              编辑
            </a>
            <Poptip
              confirm
              transfer
              title="确认删除吗？"
              on-on-ok={() => this.delete(data)}
            >
              <a style="color: #00D494">删除</a>
            </Poptip>
          </span>
        </span>
      );
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
  .outer-info {
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    > div {
      width: 49.5%;
      border-radius: 5px;
      box-shadow: 0px 2px 8px rgba(6, 22, 58, 0.06);
      box-sizing: border-box;
    }
    .left-bar {
      p {
        height: 60px;
        line-height: 60px;
        padding: 0 15px;
        font-size: 18px;
        border-bottom: 1px solid #ececec;
      }
      .type-list {
        cursor: pointer;
        height: 60px;
        line-height: 60px;
        padding: 0 15px;
        border-bottom: 1px dashed #ececec;
        display: flex;
        justify-content: space-between;
        &:hover {
          background: #faf8f8;
        }
      }
      .active {
        background: #faf8f8;
        color: #06baa1;
      }
    }
    .right-bar {
      .title {
        height: 60px;
        line-height: 60px;
        padding: 0 15px;
        font-size: 18px;
        border-bottom: 1px solid #ececec;
      }
      .right-bar-detail {
        width: 100%;
        padding: 15px;
      }
    }
  }
}
/deep/ .ivu-tree-title {
  width: 100%;
}
</style>